.accommodation_wrapper {
  .header_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .page-title {
      color: #000000b3;
    }
    .btn_groups {
      display: flex;
      span {
        font-family: "primeicons" !important;
      }
      .com_btn_style {
        padding: 8px 18px;
        font-size: 14px;
        border-radius: 0.25rem;
        margin-inline: 8px;
        align-self: center;
        border: 1px solid #0000002e;
        box-shadow: 2px 2px 8px #0000002b;
      }
      .add_preference,
      .header_config,
      .add_new_dorm {
        @extend .com_btn_style;
      }
      .add_preference {
        color: #375b83;
        background: #ffffff;
        margin-left: 0;
      }
      .header_config {
        background-color: #1d9ff6;
      }
      .add_new_dorm {
        background-color: #1961b0;
      }
    }
    @media (max-width: 900px) {
      flex-direction: column;
      margin-bottom: 1.5rem;
    }
    @media (max-width: 500px) {
      .btn_groups {
        flex-direction: column;
      }
      .add_preference,
      .header_config,
      .add_new_dorm {
        align-self: start;
        width: 100%;
        margin: 8px 0;
        text-align: center;
      }
      .header_config {
        justify-content: center;
      }
    }
  }
}

.batchDropdown {
  z-index: 2; 
}
