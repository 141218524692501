// table style start

.hfn-datatable {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: 0 0 20px 0 #4c577d05;

  .p-datatable {
    border: 1px solid $border-color;
    padding: 2px 0;

    .p-datatable-wrapper {
      min-height: 300px;
      background: #fff;
      border: 0;
    }

    .p-datatable-header {
      background: #fff;
      color: #fff;
      padding: 10px 15px;
      border: 1px solid $border-color;

      .p-inputtext {
        padding: 6px;
      }

      .p-input-icon-left > .p-inputtext {
        padding-left: 2.5rem;
      }

      .table-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        h5 {
          font-size: 20px;
          font-weight: 400;
          text-transform: capitalize;
        }
      }
    }

    .p-datatable-thead > tr > th {
      border: 0px;
    }

    .p-datatable-tbody > tr > td:not(.p-selection-column),
    .hfn-datatable-td {
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(.responsive-datatable):not(.cards-datatable) {
      .hfn-datatable-td {
        white-space: nowrap;
      }

      .p-action-column > .actions {
        text-align: center;
      }
    }

    .p-datatable-tbody > tr > td:empty:after {
      content: "-";
    }

    .p-datatable-tbody tr {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #eff2f5;
    }

    .p-datatable-tbody tr:nth-child(odd) {
      background: #00a3ff05;
    }

    .p-highlight.p-selectable-row {
      background-color: #00a3ff0d !important;
      color: #000 !important;
    }

    .p-selectable-row {
      cursor: auto !important;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      box-shadow: none !important;
      border-color: rgb(206, 212, 218) !important;
    }

    .hfn-table-status {
      font-size: 0.75rem;
      font-weight: 700;
      min-width: 60px;
      height: 1.5rem;
      line-height: 1.5rem;
      display: inline-block;
      border-radius: 10px;
      text-align: center;
      padding: 0 0.5rem;
    }

    .hfn-table-status-active {
      background-color: #50cd89;
      color: #ffffff;
    }

    .hfn-table-status-in-active {
      background-color: #f1416c;
      color: #ffffff;
    }

    .hfn-table-status-pending {
      background-color: #00a3ff;
      color: #ffffff;
    }

    .p-datatable-tbody > tr > td,
    .p-datatable-thead > tr > th {
      padding: 6px 12px;
      font-size: 14px;
      border: 0;
    }

    .p-column-title {
      font-weight: 500 !important;
    }

    .p-filter-column {
      border-top: 1px solid $border-color !important;
    }

    .p-column-filter {
      width: 100%;
      max-width: 100%;
    }

    .p-paginator {
      padding: 15px !important;
      border: 1px solid $border-color;

      .p-dropdown {
        height: 2rem !important;
      }

      .p-inputtext {
        padding: 4px !important;
      }

      .p-link {
        border-radius: 5px !important;
        margin: 0 5px;
        min-width: 2rem !important;
        height: 2rem !important;

        .pi {
          font-size: 0.8rem;
        }

        @media screen and (max-width: 768px) {
          min-width: 1.8rem !important;
        }

        @media screen and (max-width: 340px) {
          min-width: 1.5rem !important;
        }
      }
    }

    .p-paginator-bottom {
      border: 0;
      border-top: 1px solid $border-color;
    }

    .p-button-icon-only {
      width: 18px;
      background-color: transparent;
      border: 0;
      padding: 10px;

      &:hover {
        background-color: transparent !important;
      }

      .edit-icon {
        color: #46515f;
      }

      .remove-icon {
        color: $danger;
      }

      .view-icon {
        color: $primary;
      }

      .uil {
        font-size: 17.5px !important;
      }
    }

    .hfn-page-dropdown,
    .hfn-page-result {
      position: absolute;
      padding: 0 1rem;
      display: flex;
      align-items: center;
    }

    .hfn-page-result {
      left: 0;
    }

    .hfn-page-dropdown {
      right: 0;
    }

    .hfn-next-page-link {
      display: flex;
      align-items: center;
    }

    .hfn-next-page-link .goto .p-inputtext {
      text-align: center;
    }

    .p-datatable-tbody > tr > td > .p-column-title {
      display: none;
    }
  }

  .p-datatable.cards-datatable {
    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    .p-datatable-tbody {
      tr {
        td {
          .hfn-header-style-td {
            display: none;
          }

          .hfn-no-header-style-td {
            display: block;
          }
        }
      }
    }

    .p-datatable-tbody > tr > td {
      text-align: left;
      display: flex;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;

      .p-column-title {
        padding: 0.4rem;
        min-width: 35%;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4rem;
        font-weight: bold;
        // @media screen and (max-width: 768px) {
        //   min-width: 38%;
        // }
      }

      &:last-child {
        padding-bottom: 8px;
        border-bottom: 1px solid $border-color;
      }

      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }

  .p-dataview-layout-options {
    .p-highlight {
      background-color: $primary !important;
    }

    .p-highlight:hover {
      background-color: $primaryHover !important;
    }

    .p-button {
      background-color: #c8c8cb;
    }
  }

  .p-component-overlay {
    background-color: #00000005;
  }

  .p-checkbox .p-checkbox-box {
    background-color: #fff;
    // border: 0px !important;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $primary;
  }

  .info-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      word-break: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info-icon {
      font-size: 16px;
      color: $primary;
      cursor: pointer;
    }
  }

  .actions-section {
    .p-button {
      padding: 6px 11px;

      .uil {
        font-size: 16px;

        &:before {
          margin-left: 0;
        }
      }
    }
  }

  .p-sortable-column .p-sortable-column-icon {
    font-size: 12px;
    color: $primary;
  }

  .college-mou-signed {
    background-color: #50cd893d !important;
  }
}

.p-datatable {
  &:not(.responsive-datatable):not(.cards-datatable) {
    .hfn-header-style-td {
      display: block;
    }

    .hfn-no-header-style-td {
      display: none;
    }

    .hfn-datatable-td {
      white-space: nowrap;
    }

    .p-action-column > .actions {
      text-align: center;
    }
  }
}

.bulk-action-title {
  font-weight: 500;
  margin-right: 20px;
}

.gotopage-link a,
.gotopage-link span {
  text-decoration: none;
  color: #000;
  display: block;

  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
  }
}

.hfn-no-selection {
  .p-selection-column {
    .p-checkbox {
      display: none;
    }

    .p-radiobutton {
      display: none;
    }
  }
}

// table style end

@media screen and (min-width: 768px) {
  .p-datatable {
    &:not(.responsive-datatable):not(.cards-datatable) {
      .p-action-column {
        background: #fff;
        position: sticky;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        right: 0;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .p-datatable td {
    .hfn-datatable-td {
      white-space: nowrap;
    }

    .actions {
      text-align: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .p-datatable.responsive-datatable .p-datatable-thead > tr > th,
  .p-datatable.responsive-datatable .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .p-datatable.responsive-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    display: flex;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .p-datatable.responsive-datatable {
    .p-datatable-tbody {
      tr {
        td {
          .hfn-header-style-td {
            display: none;
          }

          .hfn-no-header-style-td {
            display: block;
          }
        }
      }
    }
  }

  .p-datatable.responsive-datatable
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .p-datatable.responsive-datatable .p-datatable-tbody > tr > td:last-child {
    padding-bottom: 8px;
    border-bottom: 1px solid $border-color;
  }
}

@media only screen and (max-width: 645px) {
  .hfn-datatable {
    padding: 12.5px;

    .layout-options-md {
      display: none;
    }
  }

  .p-toolbar {
    .p-ml-3 {
      margin-left: 0.5rem !important;
    }

    .bulk-edit-wrapper {
      margin-bottom: 20px;
      flex-wrap: wrap;
      padding: 10px 8px;
      background: $bg;
      border-radius: 4px;
      width: 100%;
      justify-content: space-between;

      .p-disabled,
      .p-component:disabled {
        background-color: #fff;
      }
    }

    .bulk-action-title {
      margin-bottom: 8px;
    }

    .bulk-edit-update {
      display: flex;
    }

    .add-new-btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .layout-options {
      width: 50% !important;

      .p-dataview-layout-options {
        display: block !important;
      }
    }
  }
}

@import "~assets/styles/responsive.scss";

@include md-max {
  .p-toolbar {
    display: flex;
    flex-wrap: wrap;

    .bulk-action-title {
      margin-left: 0 !important;
    }
  }

  .p-toolbar-group-right {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@include tab-max {
  .hfn-datatable {
    .p-datatable {
      .p-paginator-bottom {
        padding: 15px 0 !important;
      }

      .hfn-page-dropdown,
      .hfn-page-result {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 15px 0;
      }
    }
  }
}

.p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.upload-challan-datatable .p-datatable-thead,
.deposit-donation-datatable .p-datatable-thead,
.session-control-dashboard .p-datatable-thead {
  z-index: 0 !important;
}
