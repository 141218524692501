.approval-icon{
    display: flex;
    justify-content: center;
}
.close-icon{
    cursor: pointer;
    font-weight: 900;
    float: right;
}
.event-approval-title{
    text-align: center;
    font-size: "17px";
    font-weight: 700;
    margin-top: "48px";
}
.approval-title{
    text-align: center;
    font-size: 12px !important;
}
.event-buttons{
    display: flex;
    justify-content: space-evenly;
    margin-top: 22px !important;
}
.event-sdm-popup{
    width:"300px"
}
.event-sdm-popup {
    width: 92% !important;
    max-width: 300px !important;
}

.approve{
    color: rgb(104, 235, 104) !important;
}
.accept-event{
    background-color: #1961B0;
    color: rgb(255, 255, 255);
    width: 90px !important;
    border-radius: 1.475rem;
}
.reject-event{
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid #d9d9d9 !important;
    color: #3f4254;
    width: 90px !important;
    border-radius: 1.475rem;
}