.login-section {
  min-height: 100vh;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 94%;
  margin: auto;

  .p-card {
    border-radius: 0px;

    .p-card-body {
      padding: 0px !important;

      .loging-logo.hfnLogo img{
        width: 75%;
      }
    }
  }

  .card-wrapper {
    width: 98%;
    max-width: 385px;
  }

  .login {
    min-height: 260px;
  }

  .title {
    text-align: center;
    font-weight: bold;
  }

  .sign-up-link {
    text-decoration: none;
    color: $success;
  }

  .subtitle {
    text-align: center;
    margin: 20px 0;
  }

  .or {
    text-align: center;
    font-weight: bold;
    border-bottom: 2px solid $bg;
    line-height: 0.1em;
    margin: 15px 0;

    span {
      background: #fff;
      padding: 0 10px;
    }

  }

  .forget-pass {
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 15px;
    color: $success;
  }

  .login-button {
    width: 100%;
  }

  .social-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;

    button {
      box-shadow: 0px 0px 10px #f1f1f1 !important;
      background: #fff;
      border: solid 2px #f7f7f7 !important;
      border-radius: 8px !important;
      font-weight: bold !important;
      padding: 5px 10px !important;
      flex: auto;
      justify-content: center;
      color: #000 !important;
    }
  }

  .popup-form-section {
    margin-bottom: 0;
  }
}


// regiter 

.register-section {
  .card-wrapper {
    max-width: 637px;
  }

  .p-col-6 {
    width: 50% !important;
  }
}


.forget-section {
  .form-wrapper .p-button {
    width: 100%;
  }
}

.sso-btn {
  display: block;
  text-align: center;
  width: 100%;
}