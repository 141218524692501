.reg-restriction {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  .restriction-form-wrapper {
    p {
      padding: 4px 0px;
    }
  }

  .hint {
    padding: 0.5rem 1.25rem;
    margin: 10px;
    background: #e9e9ff;
    border: solid #696cff;
    border-width: 0 0 0 6px;
    color: #000;
  }

  .p-dropdown {
    width: 100%;
  }
}
