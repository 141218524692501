.query-builder-container {
  .group {
    background: #f3f6fe !important;
    border: 1px solid #c7dde7 !important;

    .group--header:not(.no--children):not(.hide--conjs)::before {
      top: 20px;
      height: calc(100% + 10px - 20px);
      left: 10.3px;
      width: 13px;
    }

    .group--conjunctions {
      input {
        margin-left: 5px;
        margin-right: 5px;
      }

      label {
        margin-top: 5px;
      }
    }

    .group--actions,
    .rule--header {
      opacity: 1 !important;

      button {
        margin-left: 10px;
        margin-right: 10px;
        padding: 6px 10px;
        border: 1px solid #c7dde7;
        background-color: white;
        border-radius: 6px;
        cursor: pointer;
      }

      button:hover {
        background-color: #1961b0;
        color: white;
      }
    }

    .rule--body--wrapper {
      .rule--body {
        select {
          padding: 7px;
          border-radius: 5px;
          border: 1px solid #c7dde7;
        }
      }
    }
  }
}
