// fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

// plugin css
@import url("primereact/resources/primereact.min.css");
@import url("primeicons/primeicons.css");

// app style css
@import "~assets/styles/responsive.scss";
@import "~assets/styles/theme.scss";
@import "~assets/styles/primereact.boostrap.scss";
@import "~assets/styles/common.scss";
@import "~assets/styles/regrestriction.scss";
@import "~assets/styles/superset-report.scss";
@import "~assets/styles/heartfulness-app.scss";
@import "~assets/styles/app.scss";
@import "~assets/styles/header.scss";
@import "~assets/styles/login.scss";
@import "~assets/styles/sidebar.scss";
@import "~assets/styles/loader.scss";
@import "~assets/styles/form.scss";
@import "~assets/styles/datatable.scss";
@import "~assets/styles/calendar.scss";
@import "~assets/styles/report.scss";
@import "~assets/styles/eventstemplate.scss";
@import "~assets/styles/reject-approval.scss";
@import "~assets/styles/tabview.scss";
@import "~assets/styles/registrationDashboard.scss";
@import "~assets/styles/hearts-app-tracker.scss";
@import "~assets/styles/selfregistration.scss";
@import "~assets/styles/id-card.scss";
@import "~assets/styles/accommodation.scss";
@import "~assets/styles/users.scss";
@import "~assets/styles/restrictions.scss";
@import "~assets/styles/accordion.scss";
@import "~assets/styles/setuponline.scss";

//query-builder css
@import "@react-awesome-query-builder/ui/css/styles.css";

// primeflex seprate for overwriting issue
@import url("primeflex/primeflex.css");

@import url("react-phone-input-2/lib/style.css");
