.uep-wrapper {
  margin-top: 80px;
  .reglink-rth {
    text-align: right;
  }
  .uep-event-list {
    cursor: pointer;
  }
  .user-reg-link,
  .user-reg-link:active {
    text-decoration: none;
    color: #5645b5;
    img {
      position: relative;
      top: 2px;
    }
  }
}
