.hfc-sidebar-menu {
  padding: 10px 0;

  .p-panelmenu-panel {
    padding: 6px;
    border-bottom: 1px solid #c6c6c621;
  }

  .p-panelmenu-panel:last-child {
    border-bottom: 0px;
  }

  .p-panelmenu-header {
    padding: 4px;
  }

  .menu {
    text-align: center;
  }

  .menu-item {
    padding: 1px !important;
    font-size: 13.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-family: 'Roboto';
    line-height: 18px;
  }

  .p-submenu-list .p-menuitem {
    padding-top: 8px;
  }

  .menu:hover,
  .menu-item:hover {
    background: $primary;
    color: #fff !important;
  }

  .menu-item:hover .menu-icon {
    color: #fff !important;
    margin-bottom: 7px;
  }

  .menu-item-active {
    color: #fff !important;
    margin-bottom: 7px;
  }

  .menu-item svg {
    width: 16px;
    transition: all linear 0.3s;
    margin-right: 12px;
  }

  .p-highlight svg {
    transform: rotate(-180deg);
  }

  .menu-title-wrapper {
    align-items: center;
  }

  .menu-title {
    font-size: 12px;
    line-height: 0px !important;
  }

  .menu-icon {
    display: flex;
    margin: 0 10px;
    vertical-align: middle;
    color: #88a6bb;
    font-size: 28px;
    width: 25px;
    height: 20px;
    margin-bottom: 7px;
  }

  .p-panelmenu-content {
    padding: 0;
    border: 0;
    background: unset;
  }

  .p-submenu-list li .menu {
    padding-left: 10px;
  }

}