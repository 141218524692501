html {
  font-size: 14.5px;
}

body {
  font-family: "Roboto", "sans-serif";
  font-weight: 400;
  letter-spacing: 0.45px;
  line-height: 22px;
  touch-action: manipulation;
  margin: 0;
}

body * {
  margin: 0;
  border: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

img {
  height: auto;
  max-width: 100%;
}

::-webkit-scrollbar {
  width: auto;
  height: auto;
}

.sidebar-wrapper ::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

.sidebar-wrapper ::-webkit-scrollbar-thumb {
  background-color: $primary;
  outline: 1px solid $primary;
}

.p-dialog ::-webkit-scrollbar-thumb {
  border-radius: 0px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: $secondary;
  outline: 1px solid $secondary;
}

// primeng common css
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  box-shadow: unset;
}

.card {
  box-shadow: 0 0 35px 0 #9aa1ab26 !important;
  margin-bottom: 24px;
  background-color: #fff !important;
  border: 0;
  border-radius: 0.25rem;
  padding: 1.5rem 1.5rem !important;
}

.p-card {
  border-radius: 10px;
  box-shadow: 0px 0px 3px #f1f1f1;
}

// button style start

.p-button {
  padding: 8px 20px;
  font-size: 14px;
  border: 0px !important;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.5rem;
  color: #ffffff;
  background: $primary;

  .p-button-icon {
    margin-right: 5px;
  }
}

.p-button-primary {
  background: $primary;

  &:hover {
    background-color: $primaryHover;
  }
}

.p-button-info {
  background: $info;
  color: $color;

  &:hover {
    background-color: $primaryHover;
  }
}

.p-button-secondary {
  background: $secondary;
  color: $color;

  &:hover {
    background-color: $secondaryHover;
    color: #fff;
  }
}

.p-button-success {
  background: $success;

  &:hover {
    background-color: $successHover;
  }
}

.p-button-danger {
  background: $danger;

  &:hover {
    background-color: $dangerHover;
  }
}

.p-button-warning {
  background: $warning;
  color: $color;

  &:hover {
    background-color: $warningHover;
  }
}

// button style end

// badge style start

.p-badge {
  padding: 5px 12px;
  display: inline;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  font-family: "Roboto";

  &.status-active {
    color: #50cd89;
    background-color: #e8fff3;
  }

  &.status-in-active {
    color: #f1416c;
    background-color: #fff5f8;
  }

  &.status-pending {
    color: #00a3ff;
    background-color: #f1faff;
  }

  &.pending-approval {
    color: #7239ea;
    background-color: #f8f5ff;
  }

  &.in-progress {
    color: #636363;
    background-color: #e2e2e6;
  }

  &.pending {
    color: #ffc700;
    background-color: #fff8dd;
  }

  &.cr-pending {
    color: #ffffff;
    background-color: #2196f3;
  }

  &.cr-processed {
    color: #ffffff;
    background-color: #32cd32;
  }

  &.program-in-progress {
    color: #ffffff;
    background-color: #ffa500d4;
  }

  &.program-finalized {
    color: #ffffff;
    background-color: #32cd32;
  }

  &.program-cancelled {
    color: #ffffff;
    background-color: #f1416c;
  }

  &.status-pending {
    color: #ffa500d4;
    background-color: #fff5f8;
  }

  &.status-published {
    color: #ffffff;
    background-color: #32cd32;
  }

  &.status-rejected {
    color: #f1416c;
    background-color: #fff5f8;
  }

  &.status-approved {
    color: #50cd89;
    background-color: #e8fff3;
  }
}

// badge style end

// fileicons style start
.file-type-wrapper {
  svg {
    height: 42px;
    width: 42px;
  }
}

// fileicons style end

// overlay style start

.p-dialog-mask.p-component-overlay {
  background-color: #0000003d;
}

.p-dialog {
  border: 0px !important;
  box-shadow: 0 0.5rem 1rem #0000001a;

  .hfn-datatable {
    padding: 0 !important;
  }

  @media (max-width: 786px) {
    width: 90%;
  }
}

.pagination-wr {
  color: #f3f6fe;
}

.tab-section {
  .hfn-datatable {
    padding: 0 !important;
  }
}

// overlay style end

.p-datatable-loading-icon {
  color: $primary;
}

// dialog style end

.report-toolbar {
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
}

.p-toolbar {
  background: transparent;
  // margin-bottom: 8px;
  border: 0;
  padding: 2.5px 0;
  display: flex;
  flex-wrap: wrap;

  .p-toolbar-group-left {
    width: 100%;
  }

  .p-toolbar-group-left,
  .p-toolbar-group-right {
    flex-wrap: wrap;
  }

  .p-toolbar-group-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }

  .bulk-edit-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .bulk-edit-btn-wrapper {
    display: flex;
    align-items: center;
  }

  .add-new-btn-wrapper-end {
    display: flex;
    flex-grow: 2;
    justify-content: space-between;
  }

  .datatable-title {
    h4 {
      font-size: 20px;
      font-weight: normal;
      text-transform: capitalize;
      margin-right: 20px;
    }
  }
}

.page-title {
  h5 {
    font-size: 16px;
    font-weight: normal;
    text-transform: capitalize;
    margin-right: 20px;
    margin-top: 4px;
  }
  h4 {
    font-size: 18px;
    font-weight: normal;
    text-transform: capitalize;
    margin-right: 20px;
  }
  h3 {
    font-size: 20px;
    font-weight: normal;
    text-transform: capitalize;
    margin-right: 20px;
  }
}

// breadcrumbs

.p-breadcrumb {
  background-color: transparent;
  margin-bottom: 5px;
  padding: 0.4rem 0;

  .disabled.p-menuitem-link {
    pointer-events: none;

    .p-menuitem-text {
      color: #9aacbd;
    }
  }

  ul {
    .p-breadcrumb-chevron {
      color: #1961b0;
      padding: 2px;
      margin: 0 2px;
    }
    .p-breadcrumb-chevron:first-child {
      display: none;
    }
    li {
      text-transform: capitalize;
      line-height: 25px;
    }

    li:first-child {
      display: none;
    }

    .p-menuitem-link {
      display: flex;
      align-items: center;

      .menu-icon {
        margin-right: 10px;
      }
    }
  }
}

// text
.p-text-primary {
  color: $success;
}

.p-dialog .p-dialog-header-icons {
  display: none;
}

// popup style start
.sdm-popup {
  width: 92%;
  max-width: 420px;

  .p-dialog-content {
    overflow: auto;
    max-height: 500px;
    padding: 15px 15px 0 15px;
  }
}
.login-popup {
  width: 92%;
  max-width: 420px;

  .p-dialog-content {
    overflow: auto;
    max-height: 500px;
    height: 450px;
    padding: 15px 15px 0 15px;
  }
}
.donation-form-popup {
  width: 95%;

  .p-dialog-content {
    overflow: auto;
    padding: 5px 15px 0;
  }
}
.view-more-popup {
  width: 90%;
  max-width: 700px;

  .p-dialog-content {
    overflow: auto;
    padding: 5px 10px 0;
  }
}
.regDetailsPopup {
  width: 90%;
  .p-dialog-content {
    overflow: auto;
    padding: 5px 10px 0;
  }
}
.click_menu_popup {
  display: flex;
  justify-content: space-between;
  .toggle_span_btn {
    cursor: pointer;
  }
}

.p-dialog .p-resizable-handle {
  display: none;
}

// token expire style start
.token-expire-popup {
  margin-top: 10% !important;
  z-index: 10001;
  .p-dialog-content {
    max-height: 500px;
    border-radius: 5px;
  }
}

.token-expire-box {
  color: red;
  &.mobile {
    text-align: center;
  }
}

.token-expire-login {
  text-align: center;
  &.mobile {
    padding: 10px 0;
  }
}

// token expire style end

// popup style end

// toaster style start

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  font-size: 14px;
}

.p-toast-icon-close {
  height: 25px !important;
  width: 30px !important;

  .pi-times {
    font-size: 12px !important;
  }
}

.p-toast-message-icon {
  width: 20px;
  height: 20px;
}

.p-toast-message-icon.pi.pi-check {
  background: #50cd89;
  height: 30px;
  min-width: 30px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}

.p-toast-message-icon.pi.pi-times {
  background: $danger;
  height: 30px;
  min-width: 30px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}

// toaster style end

// tabview style start
.tab-section {
  padding: 25px;
  border-radius: 10px;
  background-color: #fff;
  margin: 20px 0;

  .p-tabview-nav-link {
    border: 0 !important;
    margin: 0 !important;
    font-weight: normal !important;
    color: #000 !important;
  }

  .p-tabview-panels {
    padding: 0;
    margin-top: 20px;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: $primary;
    color: #fff !important;
  }
}

// tabview style end

// divider style start
.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid !important;
}

// divider style end

// module style start
.module-confirm-popup {
  width: auto;
  min-width: 300px;
  max-width: 600px;

  .p-dialog-title {
    display: flex;
    margin-right: 20px;
    align-items: center;
    font-size: 16px !important;
  }

  .p-dialog-header-icons {
    display: block !important;

    button {
      color: #000 !important;
    }
  }

  .p-confirm-dialog-message {
    margin: 0 !important;
  }

  .p-dialog-header {
    .p-dialog-title::before {
      height: 20px;
      width: 20px;
      font-family: "unicons";
      content: "\ea7a";
      display: inline-block;
      position: relative;
      margin-right: 6px;
      font-size: 22px;
      top: -2px;
      font-weight: 400;
      color: $primary;
    }
  }
}

.p-dialog-footer {
  .p-button {
    padding: 5px 15px;
  }

  .p-confirm-dialog-reject {
    background-color: $secondary;
    color: $color;
  }
}

.mcp-desc {
  .p-dialog-footer {
    button {
      display: none;
    }
  }
}

.cart-toogle-btn {
  background-color: $primary !important;
  width: 117px;

  &.p-highlight {
    background-color: $danger !important;
  }
}

.cart-popup {
  max-width: 1200px;
}

// module style end

// datepicker style start
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  color: #fff;
}

// datepicker style end

// error boundary style start
.error-boundary-section {
  width: 80%;
  max-width: 860px;
  margin: 0 auto;
  padding: 20px;
  .goto-home-button {
    color: $primary;
    border: 2px solid $primary !important;
    background-color: #ffffff;
  }
  .report-issue-button {
    color: $danger;
    border: 2px solid $danger !important;
    background-color: #ffffff;
    text-decoration: none;
  }
  img {
    max-width: 300px;
    height: 35px;
  }
}

// error boundary style end

// unautharized page style start
.unauthorized-page-access {
  font-weight: bold;
  color: red;
  margin: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

.unauthorized-page-message {
  color: #9aacbd;
}

// unautharized page style end

.draft-program-popup {
  max-width: 95vw;
  width: 1200px;
}

.trainer-form-popup {
  max-width: 95vw;
  width: 700px;
}

// attachment style start
.attachment-popup {
  max-width: 80vw;
  width: 800px;
}

.attachments-dialogue {
  min-height: 250px;
  max-height: calc(75vh - 143px);
  flex-wrap: wrap;
}

.attachments-media {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.attachment-upload-popup {
  z-index: 9999;
  width: 92%;
  max-width: 420px;

  .p-dialog-content {
    overflow: auto;
    max-height: 500px;
    padding: 15px 15px 0 15px;
  }
}
// attachment style end

//mou style start
.mou-download-label {
  display: flex;
  align-items: center;
}

// mou style end

//certificate style start
.college-details-header,
.p-accordion-header {
  a {
    background-color: #ffffff !important;
  }
}

.p-accordion-header-text {
  line-height: 20px;
}

.certificate-upload-form {
  display: flex;
  align-items: center;
}

.certificate-upload-box {
  flex-grow: 2;
}

.certificate-button-group {
  margin-top: -25px;
}

.certificate-gallery-button-group {
  align-items: center;
  border-left: 2px solid $border-color;
  justify-content: center;
}

.certificate-postion-label {
  align-items: center;
}

.certificate-image {
  position: relative;
  display: inline-block;
}

.certificate-position {
  position: absolute;
  display: flex;
  flex-grow: 1;
  top: 0;
  left: 0;
  font-size: 14px;
  height: 14px;
  width: 14px;
  max-height: 14px;
  max-width: 14px;
  pointer-events: none;

  i {
    font-size: 14px;
    height: 14px;
    width: 14px;
    max-height: 14px;
    max-width: 14px;
    font-weight: 700;
    color: #36b9cc;

    &::before {
      font-size: 14px;
      height: 14px;
      width: 14px;
      max-height: 14px;
      max-width: 14px;
      margin: 0px;
    }
  }
}

//certificate style end

// faq and help page style start
.item-listing {
  li {
    margin: 12px 0;
    cursor: pointer;
    list-style: none;

    &:hover {
      color: $primary;
    }
  }
}

.help-details-page {
  p {
    line-height: 22px;
  }
}

// faq and help page style end

// privilege style start
.privilege-card {
  .card {
    height: 100%;
    padding: 20px 10px !important;
  }

  .card-header {
    p {
      font-weight: 500;
      padding-left: 5px;
      margin-bottom: 10px;
    }
  }

  .card-body {
    .checkbox-wrapper {
      margin: 5px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 5px;

      label {
        width: 100%;
        cursor: pointer;
        padding: 10px 0;
      }

      &:hover {
        cursor: pointer;
        background-color: $secondary;
      }
    }
  }
}

// privilege style end

// profile style start
.profile-reset-password {
  max-width: 300px;
  margin: 0 auto;
}

// profile style end

// city autocomplet style start

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0;
}

.cac-wrapper {
  .cac-name {
    font-size: 16.5px;
    margin-bottom: 2px;
  }

  .cac-sc-wrapper {
    font-size: 0.8em;
    opacity: 0.5;
  }
}

// city autocomplet style end

// college details styel start
.college-create-program {
  flex-grow: 2;
  text-align: right;
}
// college details styel end

@import "~assets/styles/responsive.scss";

@include tab-max {
  .p-button {
    font-size: 12.5px;
    padding: 8px 16px;
  }

  .p-breadcrumb {
    ul li .p-menuitem-link {
      font-size: 12.5px;
    }
  }

  .tab-section {
    padding: 10px;

    .p-tabview-nav {
      li {
        width: 100%;

        a {
          display: block;
          text-align: center;
          border-bottom: 1px solid $secondary !important;
        }
      }
    }
  }

  .cart-popup {
    max-width: 96%;
  }

  .p-breadcrumb {
    margin-bottom: 15px;
  }
}
.cart-success {
  background-color: #50cd89;
  color: #e8fff3;
  padding: 10px;
}

.add-session-popup {
  max-width: 90vw;
  width: 1200px;
}
.select-category {
  float: right;
}
.media-player {
  max-width: 80vw;
  width: 675px;
}
.iframe-plpayer {
  width: 47vw;
  height: 30vw;
}

.trainer-myprofile-section-header {
  a {
    .p-accordion-header-text {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 15px);
    }
  }
}

.answerWidth {
  float: left;
  width: calc(100% - 60px);
  min-height: 50px;
}
.youtube-icon {
  float: right;
  cursor: pointer;
  color: red;
  font-size: 50px;
  margin-left: auto;
  width: 50px;
  display: flex;
  align-items: flex-start;
}
.hfn-accordion-content {
  min-height: 60px;
}
.button-adjust {
  margin-top: 27px;
}

.p-column-filter-clear-button {
  padding-left: 5px;
}
button.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
  background-color: #1961b0 !important;
  padding: 6px 10px !important;
  width: auto !important;
}

.hfn-text-primary {
  color: $primary;
}

.hfn-border {
  border: 1px solid $border-color;
}

.hfn-outlined-primary:not(.p-disabled) {
  color: $primary;
  border: 2px solid $primary !important;
  background-color: #ffffff !important;
}

.pending-donation-summary-preview {
  min-height: 100vh;
  width: 100%;
  display: flex;
  .main-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    .main-section {
      flex-grow: 1;
    }
  }
}

@media print {
  .print-preview-disable {
    .no-print {
      display: none;
    }
  }

  .pending-donation-summary-preview {
    .no-print {
      display: none;
    }
  }
}

.activityCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;

  .activity-card-icon {
    border-radius: 5px;
    opacity: 1;
    display: inline-block;
    margin-right: 5px;
    background: #0c3868 no-repeat 0% 0% padding-box;
    padding: 5px 5px 0px 5px;
  }

  .activity-card-title {
    display: flex;
    align-items: center;
    .activity-title {
      display: inline-block;
      margin: 4px 0 0 10px;
    }
  }
}

.rightSideMenu {
  width: 23px !important;
  padding-left: 14px !important;
}

.hfn-datatable .p-datatable .p-button-icon-only.rightSideMenu:hover {
  background-color: #4154d5 !important;
  padding-left: 14px !important;
  border-radius: 3px;
  @media (max-width: 1024px) {
    background-color: #ffffff !important;
  }
}

.hfn-datatable .p-datatable .p-button-icon-only.rightSideMenu .edit-icon:hover {
  @media (min-width: 1024px) {
    color: #fff !important;
  }
}

.right-side-menu {
  width: 11rem !important;
  font-size: 11px !important;
  padding: 0px !important;
  .p-menu-list .p-menuitem {
    border-bottom: 1px solid rgba(205, 205, 205, 0.5);
  }

  .p-menu-list .p-menuitem:last-child {
    border-bottom: 0px;
  }
}
.custom_wrapper {
  display: flex;
  justify-content: space-between;
}
.custom_wrapper .page-title {
  justify-content: space-between;
}

.upload-picture {
  display: flex;
  align-items: center;
  position: relative;

  .picture-count {
    flex-grow: 0;
    flex-shrink: 1;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;

    div {
      color: #fff;
      font-weight: bold;
      font-size: 3rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .add-more {
    cursor: pointer;
    position: relative;
  }

  .picture-message {
    margin-left: 20px;
  }
}
.input-file-type-container {
  // width: 100%;
  position: relative;
  .input-file-type-wrapper {
    .form-label {
      width: 100%;
      .input-with-icon {
        align-items: center;
        display: flex;

        .paper-clip-icon {
          right: 15px !important;
          font-size: 25px;
          transform: rotate(180deg) scaleX(-1);
        }

        .icon {
          position: absolute;
          right: 10px;
        }

        .iconDown {
          cursor: pointer;
          font-size: 30px;
          color: #bebebe;
          right: 5px;
          bottom: 4px;
          background: #f9f9f9;
          width: 25px;
        }

        .calenderIcon {
          cursor: pointer;
          font-size: 20px;
          color: #bebebe;
          right: 25px;
        }
      }
    }

    .input-calender-type {
      .is-invalid-feedback {
        opacity: 0;
      }
      .react-datepicker-wrapper {
        opacity: 0;
      }
      width: 99%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // z-index: 10; // commented this to enable next month of start date
    }

    .input-file-type {
      width: 100%;
      height: 100%;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 10;
    }

    .input-file-type-testmonial {
      .input-file-type-opacity {
        opacity: 0;
      }
      width: 100%;
      height: 100%;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }
}

.upload-file-container {
  width: fit-content;
}
.more-menu-buttons {
  display: flex;
  justify-content: flex-end;
}

.coordinators-wr {
  .page-title {
    display: none;
  }
  .p-divider {
    display: none;
  }
}

.img-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  position: relative;
  padding: 10px 0;
  // height: 300px;

  h3 {
    width: 100%;
    font-weight: 500;
    margin: 0 0 10px 0;
    border-bottom: solid 1px #ddd;
    padding: 0 0 7px 0;
    font-size: 15px;
  }

  .img-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin: 4px;

    img {
      max-width: 150%;
      width: 100px;
    }

    &.docs {
      img {
        width: 40px;
      }
    }

    .pi-trash {
      cursor: pointer;
    }
  }
}

.pdf-grid {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  border-bottom: 3px solid #ccc;
  border-top: 3px solid #ccc;
  border-left: 0;
  border-right: 0;
  border-style: dashed;
  padding: 10px 0;

  .pdf-item {
    margin: 4px;
    flex: 0 1 calc(25% - 8px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #404040;

    img {
      align-self: center;
      height: auto;
      max-width: 100%;
      width: 50px;
    }
  }
}

.dashboard-list {
  border-radius: 5px;
  opacity: 1;
  display: inline-block;
  margin-right: 5px;
  background: #0c3868 no-repeat 0% 0% padding-box;
  padding: 10px;
  .dashboard-icon {
    color: #fff;
    font-size: 20px;
  }
}
.connect-admin-model {
  min-height: 300px;
}
textarea {
  resize: vertical;
}
.mt10 {
  display: flex;
  align-items: center;
  margin-top: 10px !important;
}
.download-link {
  text-decoration: none !important;
}
.hint {
  font-size: 12px;
  color: #818181;
}
.p-dialog-content {
  .p-datatable-thead {
    position: inherit;
  }
}
.card-remove {
  .close {
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
  }
  .pi-trash {
    color: red !important;
  }
}
.btn-display-none {
  display: none;
}
.testimonial-badge {
  display: flex !important;
  span {
    display: flex;
    margin-left: 10px;
    img {
      padding-left: 5px;
    }
  }
}
.eventTemplate {
  .p-checkbox-label {
    line-height: 18px;
    margin-right: 10px;
  }
}
.mh-100 {
  min-height: 100px;
}
.icon-danger {
  color: red !important;
}
.coUserCheckbox {
  padding: 0.2rem;
  display: flex;
  align-items: center;
  input {
    vertical-align: middle;
  }
  label {
    line-height: 20px !important;
    padding: 0px 6px;
    margin-bottom: 0px;
  }
}
.AbhyasiSearchWrapper {
  .card {
    padding: 1rem !important;
  }
  .card-checkbox {
    padding: 0.6rem 0rem;
  }
}
.session-card {
  padding: 0.5rem 1.5rem !important;
  .carousel-content-wrapper {
    padding: 0px !important;
  }
}

.cusFontSize {
  span.p-dropdown-label {
    font-size: 14px;
  }
}
@media only screen and (min-width: 960px) {
  .hfn-datatable .p-datatable .p-paginator {
    .hfn-page-dropdown,
    .hfn-page-result {
      padding: 0 0.5rem;
    }
  }
  .hfn-prev-page-link {
    margin-left: 10px;
  }
  .hfn-datatable .p-datatable .p-paginator .p-link {
    margin: 0 4px !important;
  }
}
.coordinator-card {
  .uil-name::before,
  .uil-hfn_coordinator-name::before,
  .uil-client_coordinator-name::before,
  .uil-session_hfn_volunteer-name::before {
    content: "\ec2c";
  }
  .uil-mobile::before,
  .uil-hfn_coordinator-mobile::before,
  .uil-client_coordinator-mobile::before,
  .uil-session_hfn_volunteer-mobile::before {
    content: "\eb1c";
  }
  .uil-email::before,
  .uil-hfn_coordinator-email::before,
  .uil-client_coordinator-email::before,
  .uil-session_hfn_volunteer-email::before {
    content: "\e863";
  }
  .uil-hfnCo::before {
    content: "\ec26";
  }
}
// label.hfn_coordinator-hfn_coordinator,
// label.client_coordinator-client_coordinator,
// label.session_hfn_volunteer-session_hfn_volunteer {
//   display: none;
// }
.qrCode {
  cursor: auto;
}

div[class*="-control"] {
  border-color: #919191;
}
.p-invalid {
  input.form-control,
  .flag-dropdown {
    border-color: #f1416c;
  }
  .flag-dropdown {
    border-right: 1px solid #cacaca;
  }
}

.bulk-errors {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 10px 0 10px;
  height: 200px;
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 3px;
  overflow-y: scroll;
}

.bulk-errors::-webkit-scrollbar-thumb {
  background-color: #a94442;
  outline: 1px solid #a94442;
  border-radius: 30px;
}

.bulk-error-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copy-errors {
  cursor: pointer;
  font-size: 21px;
  color: #6366f1;
}

.closedEvent {
  margin-top: 100px;
  background-color: #f8d7da;
  color: #000;
  padding: 1rem;
  border-radius: 6px;
  h3 {
    font-weight: normal;
  }
  p {
    font-size: 16px;
    padding: 1rem;
    color: red;
  }
}
.name_card {
  border: 1px solid #919191;
  border-radius: 5px;
  height: 100px;
  overflow: hidden;
}
.closedEventHfnApp {
  color: #000;
  padding: 1rem;
  p {
    font-size: 14px;
    color: red;
  }
}

.printButton {
  margin-left: 20px;
}
.leftAlignedButton {
  display: flex;
  justify-content: flex-start;
}
.rightAlignedButton {
  display: flex;
  justify-content: flex-end;
}
.rightAlignedMobileButton {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 768px) {
  .rightAlignedMobileButton {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .rightAlignedMobileButton button {
    margin-top: 5px;
  }
}

@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}

.userAltPhoto {
  font-size: 5rem;
  cursor: pointer;
}
.uploadPhotoWrapper {
  .fieldWrapper {
    margin: 0 auto;
  }
  .p-inputtext {
    border: none;
  }
}

.p-column-filter-menu-button {
  display: none;
}
// Temporary fix for add clear filter - primereact upgrade issue
.p-column-filter-clear-button {
  svg.p-icon {
    visibility: hidden;
    width: 0px;
    margin-left: 2px;
  }
}
.p-column-filter-clear-button:after {
  content: "\e9b7";
  font-family: "primeicons";
}
// Temporary fix for add clear filter - primereact upgrade issue

.hfn-link {
  text-decoration: none;
  color: $primary;
  cursor: pointer;
}
.hint-search {
  font-size: 12px;
  color: #5c5c5c;
}
.p-link {
  color: #fff;
}
.link-btn {
  padding: 0px;
  font-size: 14px;
  border: 0px !important;
  font-weight: 500;
  color: $primary;
  text-decoration: underline;
  background: none;
}
.processing {
  text-align: center;
  padding-top: 15px;
  color: $primary;
}

.languageDropdown {
  position: fixed;
  bottom: 0px;
  right: 4px;
  img {
    width: 40px;
  }
}
.testimonial-icon-wrapper {
  display: flex;
  img {
    padding-left: 2px;
  }
}
.p-tooltip .p-tooltip-text {
  max-width: 300px;
}

.footerButtons {
  display: flex;
  justify-content: end;
  margin-top: 3rem;
}
.card-body {
  label {
    min-width: 110px;
    overflow: hidden;
    display: inline;
  }

  .view-template-popup {
    width: 90%;
    max-width: 800px;

    .p-dialog-content {
      overflow: auto;
      padding: 5px 10px 0;
    }
  }
  p {
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.setup-online {
  .carousel-content-wrapper {
    padding: 0px !important;
  }
}
.approver-block {
  border-left: 1px solid #dee2e6;
  margin: 0px 10px 0px 10px;
  padding-left: 10px;
  .card-body {
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    .close-cursor {
      cursor: pointer;
      float: right;
      font-size: 16px;
    }
  }
  @media (max-width: 768px) {
    border: none;
    margin: 0;
    padding: 0;
  }
}

.configurationsModal {
  .page-title h4,
  .p-divider {
    display: none;
  }
}
.popupinline-content {
  min-height: 200px;
  padding: 10px;
}
.session-dashboard-card {
  background-color: rgb(247, 251, 255);
  padding: 10px;
  .tittle {
    background-color: rgba(226, 242, 255, 0.56);
    padding: 15px 0px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .row-item {
    background-color: rgba(226, 242, 255, 0.56);
    padding: 10px;
    margin: 10px;
    .val-cls {
      font-size: 30px;
      padding-bottom: 10px;
    }
    .key-cls {
      font-size: 15px;
      padding-bottom: 10px;
    }
  }
}
.btn-group {
  button {
    margin: 5px;
  }
}
.align-center {
  margin: 0 auto;
}
.hfn-header-style-td-img {
  width: 150px;
}
.img-uplod {
  text-align: right;
}

.approveCommentsWrapper {
  padding: 10px;
  .event-status {
    border-bottom: 1px solid $secondary;
    padding: 10px;
    display: block;
  }
}
.event-preview-tab {
  button.p-tabview-nav-link span {
    max-width: 200px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.p-image-toolbar {
  padding: 10px;
  button {
    padding: 2px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
}
.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.constraintTittle {
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  font-size: 16px;
}
.datetime-wrapper {
  button {
    border-radius: 0px;
  }
  #basic {
    border-radius: 0px 4px 4px 0px;
  }
}
.discard-email-prev {
  text-align: right;
  margin: 10px;
}
.messageblock {
  .tittle {
    background-color: #c5c9cd;
    font-size: 16px;
  }
  .description {
    font-size: 14px;
    padding: 10px 10px 10px 20px;
  }
}
.stay-list {
  list-style: none;
  li {
    .label {
      width: 100px;
      font-weight: bold;
    }
    .desc {
      padding-left: 10px;
    }
    display: flex;
  }
}
.transportModal {
  min-height: 200px;
}
.iFrameWrapper {
  padding-top: 10px;
  iframe {
    background-color: #eeeeee;
    height: 90vh !important;
    width: 100% !important;
    border: 0px;
  }
}
.update-registrant-modal {
  min-height: 400px;
}
.m-dashboard-btn {
  button {
    width: 100%;
  }
}
.registrantDetailsTable {
  font-size: 12px;
}

.websiteBlock {
  .hideField {
    display: none;
  }

  .hint-search {
    display: none;
  }
  .approverField {
    .p-button-secondary {
      display: none;
    }
  }
  .AbhyasiSearchWrapper {
    .p-lg-4 {
      width: 50% !important;
    }
  }
}
.archived-wrapper {
  z-index: 2;
}
.input-wrapper {
  width: 100%;
  input,
  .p-dropdown,
  .p-inputtext,
  .p-calendar {
    width: 100%;
  }
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-wrap: wrap;
  display: block;
  width: 320px;
}
.hfn-datatable {
  .p-dropdown-item {
    width: auto !important;
  }
}
.badge-card {
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 3px;
  overflow: hidden;
  margin: 5px !important;
  padding: 3px;
  .badge-card-body {
    h5.badge-card-title {
      margin-bottom: 2px;
    }

    p.badge-card-text {
      // margin: 0;
      line-height: 1.5;
      font-size: 10px;
    }
  }
}

.view-download-popup {
  width: 50%;
  max-width: 700px;

  .p-dialog-content {
    overflow: auto;
    padding: 5px 10px 0;
  }
}
.download_participants {
  display: flex;
  flex-wrap: wrap;
}
.download_participants > div {
  flex: 0 0 calc(33.33% - 20px);
  margin-right: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.maintenance-mode {
  margin: 0 auto;
  padding-top: 100px;
  height: 100vh;
  h1 {
    line-height: 30px;
    margin-top: 16px;
  }

  background: #f5f8f4;
  .left-content {
    padding: 40px 10px 10px 60px;
  }
  h1,
  p {
    padding-top: 10px;
  }
  .right-content {
    text-align: center;
  }
}
.day {
  font-size: 12px;
  color: black;
}
.remove-asterisk em {
  visibility: hidden;
}
