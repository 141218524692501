.self-reg {
  display: block !important;
  border-right: none !important;
}
.header-selreg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  padding-right: 10px;
  .signOut {
    img {
      height: 32px;
      width: 32px;
      margin-right: 10px;
    }
  }
  .user-info {
    border-width: 0 1px;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.selfRegForm {
  padding-top: 30px;
  justify-content: center;
  .regForm {
    button {
      margin-top: 2rem;
    }
  }
  h3 {
    font-size: 18px;
    padding: 0.5rem 0rem;
    text-align: center;
    font-weight: normal;
  }
  p {
    text-align: center;
  }
  .hint {
    color: #757575;
  }
}
