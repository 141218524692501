.card-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  .card-dimensions {
    position: relative;
    width: 320px;
    height: 510px;
    border: 2px solid #000000;

    .card-details {
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo-styles {
        margin-top: 6px;
        height: fit-content;
        max-width: 68%;
      }

      .role-styles {
        font-size: 18px;
        font-weight: 700;
        margin-top: 5px;
        text-transform: uppercase;
      }

      .pic-styles {
        margin-top: 10px;
        width: 100px;
      }

      .name-styles {
        margin: 15px 80px 0 80px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        line-height: 1.1;
      }

      .abhyasi-id-styles {
        margin-top: 18px;
        font-size: 20px;
      }

      .code-styles {
        margin: 10px 5px 0 12px;
        display: flex;
        align-items: center;
      }

      .issued-by-parent {
        margin-top: 0;
        font-size: 12px;
      }
    }

    .gradient {
      background: rgb(87, 166, 177);
      background: linear-gradient(
        90deg,
        rgba(87, 166, 177, 1) 0%,
        rgba(110, 185, 194, 1) 50%,
        rgba(0, 185, 235, 1) 100%
      );
    }

    .top-border {
      top: 0;
      height: 10px;
    }

    .bottom-border {
      position: absolute;
      bottom: 0;
      height: 25px;
      width: 100%;
    }

    .id-card-back {
      justify-content: center;
      height: 98%;

      .sahaj-marg-text {
        margin-top: 3px;
        font-size: 17px;
        font-weight: 700;
        color: #00b9eb;
      }

      .kanha-address {
        margin-top: 6px;
        text-align: center;
        white-space: pre-wrap;
        font-size: 10px;
        font-weight: 600;
        color: #292929;
        line-height: 1.4;
      }

      .return-text {
        margin-top: 30px;
        font-size: 9px;
      }
    }
  }
}

@media print {
  .card-container {
    -webkit-print-color-adjust: exact;
  }
}
