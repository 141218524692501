.hfn {
  .apps-main-wrapper {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    background: #fff6f6;
    font-size: 14px;

    .mobile-event-listing ul li.previous,
    .mobile-event-listing ul li.next {
      background-color: transparent;
      border: none;
      width: 60px;
    }
    .event-tittle {
      font-size: 16px;
      padding: 0.5rem 0rem;
      font-weight: bold;
      color: #202020;
    }
    .rth {
      text-align: right;
    }
    .reg-link {
      color: #643e94;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      position: relative;
      padding-right: 20px;
    }
    .reg-link .icon {
      display: inline-block;
      vertical-align: middle;
      transition: all 0.3s ease-in-out;
      transform: translatex(0);
      position: absolute;
      right: 0;
      top: 0;
    }
    .txt-wrap {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .hfn-selfreg {
      background-color: #fff;
    }
  }
}
