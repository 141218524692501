.p-tabview .p-tabview-nav {
  background: #ffffff;
  //   border: 1px solid #6b90b7;
  //   border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #6b90b7;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #6b90b7 transparent;
  background: #ffffff;
  color: #6c757d;
  padding: 1rem;
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}

.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background: #ffffff;
  border-color: #1961b0;
  color: #6c757d;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: #1961b0;
  color: #fff !important;
}

.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-nav-btn.p-link {
  background: #ffffff;
  color: #1961b0;
  width: 3rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #c7d2fe;
}

.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-tabview-selected {
  border-bottom: 2px solid #1961b0;
  bottom: 0;
  color: #1961b0;
  font-weight: 500;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: transparent !important;
}
