.report-dashboard {
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 10px;
}

.p-button-report {
  background: #ffc107;
  color: #fff;
}
.m-superset {
  .report-tittle {
    display: none;
  }
}
