.reg-restriction-notes {
  padding: 5px;
  font-size: 12px;
  color: #5c5c5c;
  .link {
    cursor: pointer;
    color: #1961b0;
  }
  .p-message {
    font-size: 12px;
    .p-message-wrapper {
      padding: 0.5rem;
    }
    .p-message-wrapper {
      align-items: baseline;
    }
    .p-message-icon {
      display: none;
    }
    .p-message-detail {
      padding-left: 10px;
    }
  }
}
