/* Status info */
.organization-wr {
  background: #fff;
  border-radius: 4px;
  .arrow {
    border-radius: 50%;
    &.right {
      right: -3px;
    }
  }
}
.status_info {
  display: grid;
  grid-template-columns: repeat(3, 34%);
  .inner_card {
    color: #ffffff;
    margin-right: 0.5rem;
    &:last-child {
      margin-right: none;
    }
    .stats-card {
      min-height: 100px;
      margin: 0;
      padding: 16px !important;
    }
    .paleBlueBg {
      background: #63b9e4 !important;
    }
    .greenBlueBg {
      background: #219ebc !important;
    }
    .darkBlueBg {
      background: #339ade !important;
    }
  }
  @media (max-width: 840px) {
    .font-light-12 {
      font-size: 12px;
      line-height: 17px;
      margin: 10px 0 0 0;
    }
    .font-heavy-30 {
      font-size: 28px;
      font-weight: 600;
      margin: 0 0 10px 0;
    }
  }
  @media (max-width: 380px) {
    grid-template-columns: repeat(3, 34%);
  }
}
.mobile-event-listing {
  padding: 1rem 0rem !important;
  .event-item {
    box-shadow: #cdcdcd 1px 1px 3px;
    margin: 0 0 12px 0;
    border-radius: 6px;
    padding: 10px 8px;
    font-size: 13px;
    background: #ffffff;
    .event-link-wr {
      text-align: right;
      p {
        margin: 0 6px;
        display: inline-block;
        span {
          font-size: 17px;
          color: #434343;
          font-weight: 100 !important;
        }
        &:last-child {
          span {
            color: #fff;
            background: #53a5ff;
            border-radius: 50%;
            font-size: 20px;
          }
        }
      }
    }
    .org-wr {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }

  &.pageinfo-wr {
    color: #666;
    font-size: 13px;
  }

  select {
    padding: 10px;
    background: transparent;
    color: #666;
  }

  ul {
    text-align: center;
    li {
      list-style: none;
      display: none;
      &.previous,
      &.next {
        display: inline-block;
        padding: 8px 10px;
        border: solid 1px #53a5ff;
        background: #53a5ff;
        color: #ffffff;
        font-size: 18px;
        margin: 0 4px;
        &.disabled {
          opacity: 0.7;
        }
      }
    }
  }
}

.carousel-arrow {
  .arrow {
    position: absolute;
    top: 0;
    height: 100%;
    background: #fff;
    // padding: 1px;
    border: 0;
  }

  .right {
    right: -1px;
    padding-right: 3px;
    img {
      &:hover {
        border-radius: 50%;
      }
    }
    // The below media query is for safari/iOS browser
  }

  .left {
    left: 0px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    padding-left: 3px;

    img {
      &:hover {
        border-radius: 50%;
      }
    }
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      right: 2px;
    }
  }
}

.carousel-home {
  .carousel-btn-default {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 100% !important;
    color: #404040;
    font-weight: bold;
    border: 1px solid #cfcfcf;
    padding: 7px 13px;
    background-color: #fff;
    border-radius: 6px;
    width: auto;
    white-space: nowrap;

    &:hover {
      background: #22c8fc;
      border: 1px solid #22c8fc;
      color: #fff;
    }
  }

  .carousel-btn-info {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 100% !important;
    color: #fff;
    font-weight: bold;
    border: 1px solid #22c8fc;
    padding: 7px 13px;
    background-color: #22c8fc;
    border-radius: 6px;
    width: auto;
    white-space: nowrap;
  }

  .react-multi-carousel-track {
    .react-multi-carousel-item {
      padding: 0 5px;
    }
  }
}

.carousel-card-wrapper {
  min-height: 98px;
  .card {
    height: 98px;
  }
  .carousel-content-wrapper {
    .with-icon {
      margin-right: 0.5rem;
    }

    .carousel-content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
    }
    padding: 0 0 0 20px;
  }
}

.card-remove {
  position: absolute;
  right: 1px;
  padding: 6px 10px;
  font-size: 15px;
  top: 11px;
  cursor: pointer;
  &.coordinators {
    top: 5px;
  }
  .close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    i {
      color: #6f6f6f;
    }
  }
}

.card-remove-modal {
  padding-top: 0;
}

.carousel-content-wrapper {
  .with-icon {
    margin-right: 0.5rem;
  }

  .carousel-content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.user-detail-card {
  .react-multi-carousel-list {
    .carousel-arrow {
      button {
        top: 40% !important;
        height: fit-content;
        background: transparent;
        cursor: pointer;
        &.arrow.bg-white {
          background: #ffffff !important;
          border-radius: 50%;
        }
      }
    }
  }

  .react-multi-carousel-item {
    // width: auto !important;
    .card {
      border-width: thin;
      margin: 18px 0;
      color: #242424;
      min-height: 105px;
      border: solid 1px #e8e7e7;
      width: 95%;
      margin: 18px auto;
      padding: 1rem !important;
      box-shadow: 1px 1px 7px 2px #ededed !important;
    }
  }
}

.setupOnlineRefg {
  .card {
    min-height: 120px;
  }
}

.carousel-home {
  .react-multi-carousel-list {
    height: 32px;
  }
}

.input-search {
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;

  input[type="text"] {
    width: 94%;
    @media (max-width: 992px) {
      width: 95%;
    }
    @media (max-width: 800px) {
      width: 90%;
    }
  }

  .search-apply-btn {
    margin-left: 10px;
    position: absolute;
    right: 0;
    top: 0;
    background: #dfdfdf;
    width: 40px;
    height: 40px;
    border-radius: 0 5px 5px 0;
    border-style: solid solid solid none;
    cursor: pointer;
  }
  .search-apply-btn-approver {
    margin-left: 0;
    position: relative;
    right: 0;
    top: 0;
    background: #dfdfdf;
    width: 40px;
    height: 40px;
    border-radius: 0 5px 5px 0;
    border: 1px solid #ced4da;
    border-style: solid solid solid none;
    cursor: pointer;
  }
  .search-apply-btn-small {
    height: 35px;
  }
  .input-search-icon {
    position: absolute;
    z-index: 2;
    display: block;
    width: 3.375rem;
    height: 3.375rem;
    line-height: 3.75rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    font-size: 22px;
  }

  .input-search-control {
    // padding-left: 3.375rem;
    padding-right: 1.375rem;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 40px;
  }

  .pi-search {
    font-size: 18px;
    color: #797f84;
  }

  .input-search-control-small {
    // padding-left: 3.375rem;
    padding-right: 1.375rem;
    padding-top: 7px;
    padding-bottom: 7px;
    width: 100%;
    height: 35px;
  }

  .search-icon-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .btn-search-icon {
    padding-left: 1px;
    // padding-top: 3px;
    // The below media query is for safari/iOS browser
    // @media not all and (min-resolution: 0.001dpcm) {
    //   @supports (-webkit-appearance: none) {
    //     right: 3px;
    //   }
    // }
  }
}

.add-session-btn {
  background: #22c8fc;
  border: 1px solid #22c8fc;
  white-space: nowrap;
  color: #fff;
  padding: 8px 8px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #06abdf;
    border: 1px solid #06abdf;
  }
}

.sessionGridTitle {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.sessionGridBold {
  font-weight: 600;
}

//Session Tabs
.session-tabs {
  li.p-unselectable-text {
    width: 33%;
    text-align: center;
  }
  .p-tabview .p-tabview-nav {
    background: transparent;
  }
}

.camera-hint {
  font-size: 12px;
  font-style: italic;
  color: #666;
}
