.eventTemplate {
  .templateTitle {
    margin-bottom: 15px;
    text-align: left;
    letter-spacing: 0px;
    color: #303030;
    opacity: 1;
  }

  .templateName {
    text-align: left;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: small;
    padding-bottom: 5px;
    color: #3a3a3a;
    opacity: 1;
  }

  .templateSubTitle {
    margin-top: 10px;
    text-align: left;
    letter-spacing: 0px;
    color: #303030;
    opacity: 1;
  }

  .templateDesc {
    font-weight: 200;
    font-size: smaller;
    padding-bottom: 3px;
  }

  .templateInput {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    opacity: 1;
    margin-bottom: 10px;
    width: 390px;
    height: 38px;
  }

  .eventTemplateCard {
    display: inline-block;
    border-radius: 0px !important;
    box-shadow: 0px 0px 0px #fff !important;
    .p-card-body {
      padding: 0.5rem !important;
      .p-card-content {
        padding: 0 !important;
      }
    }
  }

  .eventTemplateCard .p-card-body {
    padding-bottom: 0px;
  }

  .templateCard {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    opacity: 1;
    padding: 10px;
    height: 140px;
  }

  .templateCard:hover {
    background: #f3f6fe 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #1961b0;
  }

  .templateCard-selected {
    background: #f3f6fe 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #1961b0;
  }

  .template-card-content {
    text-align: left;
    padding-top: 10px;
    font-size: 12px;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    min-height: 150px;
    cursor: pointer;
  }

  .template-card-title {
    display: flex;

    span {
      margin-right: 10px;
      margin-top: 2px;
    }

    .card-icon {
      margin-top: 5px;
    }

    .card-title {
      min-width: 145px;
      cursor: pointer;
    }

    .edit-icon {
      cursor: pointer;
      margin-top: 5px;
      font-size: small;
    }
  }

  .event-template-block-title {
    padding: 10px;
  }

  .event-block-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 22px !important;
  }
}

.edit-template-block {
  max-width: none;
}
.m20 {
  margin: 20px 4px !important;
}
